<template>
  <KPage>
    <!-- Filtto -->
    <FilterBar @filter="onFilter" />

    <SectionHeader title="Gráfico" />
    <!-- Gráfico -->
    <v-row>
      <v-col cols="12" md="6">
        <GraphicPie v-bind="chartIncome" />
      </v-col>
      <v-col cols="12" md="6">
        <GraphicPie v-bind="chartExpense" />
      </v-col>
    </v-row>

    <SectionHeader title="Balancete" />
    <!-- Balancete -->
    <v-card rounded outlined>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        sort-by="category_fullname"
        group-by="category_fullname"
        no-data-text="Nenhuma movimentação encontrada"
        no-results-text="Nenhuma movimentação encontrada"
        outlined
        disable-sort
        :sort-desc="true"
        disable-pagination
        disable-filtering
        hide-default-footer
        show-group-by
      >
        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.date="{ item }">
          {{ item.date }}
        </template>

        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.income="{ item }">
          <span v-if="item.multiplier == '1'" class="my-0 text-no-wrap caption">
            {{ item.description }}
          </span>
        </template>

        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.expense="{ item }">
          <span
            v-if="item.multiplier == '-1'"
            class="my-0 text-no-wrap caption"
          >
            {{ item.description }}
          </span>
        </template>

        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.payed_amount="{ item }">
          <span
            v-if="item.multiplier == '1'"
            class="my-0 text-no-wrap caption green--text"
          >
            {{ item.payed_amount | currency }}
          </span>
          <span
            v-if="item.multiplier == '-1'"
            class="my-0 text-no-wrap caption red--text"
          >
            {{ item.payed_amount | currency }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </KPage>
</template>

<script>
import moment from "moment";

import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import GraphicPie from "@/components/Graphic/Pie";

import FilterBar from "@/views/Finance/dashboard/FilterBar";
import { currencyBR } from "klawtil";

export default {
  components: {
    KPage,
    SectionHeader,
    GraphicPie,

    FilterBar,
  },

  data() {
    return {
      items: [],
      headers: [
        { text: "Data", value: "date", width: 20, groupable: false },
        { text: "Categoria", value: "category_fullname", width: 20 },
        {
          text: "Receita",
          value: "income",
          sortable: false,
          groupable: false,
          width: "45%",
        },
        {
          text: "Valor",
          value: "payed_amount",
          sortable: false,
          groupable: false,
          width: "10%",
        },
        {
          text: "Despesa",
          value: "expense",
          sortable: false,
          groupable: false,
          width: "45%",
        },
      ],
      filter: {},
    };
  },

  computed: {
    chartIncome() {
      return {
        title: "Receitas por Categoria",
        names: "title",
        values: "payed_amount",
        height: 400,
        _labelFormatter: "{a} <br/>{b} : {c} ({d}%)",
        labelFormatter: params => `
          ${params.seriesName} <br />
          ${params.data.name} : ${currencyBR(params.data.value)} ( ${
          params.percent
        }% )
        `,
        series: this.grouped("1"),
      };
    },

    chartExpense() {
      return {
        names: "title",
        values: "payed_amount",
        title: "Despesas por Categoria",
        height: 400,
        _labelFormatter: "{a} <br/>{b} : {c} ({d}%)",
        labelFormatter: params => `
          ${params.seriesName} <br />
          ${params.data.name} : ${currencyBR(params.data.value)} ( ${
          params.percent
        }% )
        `,

        series: this.grouped("-1"),
      };
    },
  },

  methods: {
    /**
     *
     */
    async onFilter(payload) {
      try {
        this.$loading.show();

        await Promise.all([
          this.loadBalance(payload),
          // this.loadResume(payload)
        ]);

        this.$loading.hide();
      } catch (error) {
        console.log(error);
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    async loadBalance(params) {
      try {
        const { data } = await this.$api.get(
          "/financials/reports/balance",
          params
        );
        this.items = data.data;
      } catch (error) {
        console.log(error);
        this.$message.serverError(error);
      }
    },

    grouped(multiplier) {
      const income = [];

      this.items
        .filter(item => item.multiplier === multiplier)
        .forEach(item => {
          // já tem um com esse no income?
          const index = income.findIndex(
            row => row.title === item.category_fullname
          );

          if (index === -1) {
            income.push({
              title: item.category_fullname,
              payed_amount: +item.payed_amount,
            });
          } else {
            income[index].payed_amount += +item.payed_amount;
          }
        });

      return income;

      console.log("income", income);
    },
  },
};
</script>

<style>
</style>
