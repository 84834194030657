<!--
@example
<GraphicPie
    elevation="0"
    :series="[
      { category_name: 'desepsa', quantity: 44 },
      { category_name: 'reccei', quantity: 16 }
    ]"
    names="category_name"
    values="quantity"
    sheetColor="white"
    color="white"
    title="Distribuição Parcial de Votos"
    // formatador como string
    labelFormatter: "{a} <br/>{b} : {c} ({d}%)",
    // formatador como função
    :labelFormatter: "params => `
      ${params.seriesName} <br />
      ${params.data.name} : ${params.data.value} ( ${params.percent}% )
    `"
  />
'


-->

<template>
  <v-card min-width="350" outlined>
    <v-sheet :height="height">
      <v-chart
        :style="`height: ${height}px`"
        :options="data"
        :init-options="initOptions"
        ref="pie"
        autoresize
      />
    </v-sheet>
    <v-divider v-if="title"></v-divider>
    <v-card-actions v-if="title" class="caption" style="align-items: center">
      <p class="ma-0 d-block text-truncate">{{ title }}</p>
    </v-card-actions>
  </v-card>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

export default {
  name: "graphic-pie",
  components: {
    "v-chart": ECharts
  },
  props: {
    // Título do gráfico
    title: String,
    // Conjunto de dados
    series: Array,
    // O nome da chave que contém os valores
    values: String,
    // O nome das chaves de valores que contém os nomes
    names: String,
    // Cores para as séries:
    colors: [String, Array],
    // Atributos para o v-card do gráfico
    // Altura da elevação
    elevation: { type: [String, Number], default: 1 },
    // label-formatter. Função que passa os parâmetros definidos em
    // https://echarts.apache.org/en/option.html#tooltip.formatter
    // e formata o label do gráfico.
    labelFormatter: {
      type: [String, Function],
      default: "{a} <br/>{b} : {c} ({d}%)"
    },
    // Altura
    height: { type: Number, default: 230 }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      }
    };
  },
  computed: {
    data() {
      const data = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: this.labelFormatter
        },
        legend: {
          orient: "center",
          left: "left"
        },
        series: {
          name: this.title,
          // roseType: "radius",
          color: Array.isArray(this.colors) ? this.colors : null,
          align: "edge",
          type: "pie",
          radius: "55%",
          center: ["50%", "50%"],
          data: this.series.map(serie => ({
            value: serie[this.values],
            name: serie[this.names]
          }))
        }
      };

      return data;
    }
  }
};
</script>

<style scoped>
.echarts div {
  width: 200px;
  height: 100%;
}
.echarts {
  width: 100%;
  height: 100%;
}
</style>
