<template>
  <KPage>
    <!-- Filtto -->
    <FilterBar @filter="onFilter" />

    <SectionHeader title="Gráfico" />
    <!-- Gráfico -->
    <GraphicPie v-bind="chartSettings" />

    <v-row class="my-0 px-1">
      <!--  -->
      <v-col cols="12" class="pb-0">
        <SectionHeader title="Receitas x Despesas mensal" />
      </v-col>

      <!--  -->
      <ResumeCardList :items="resume" />
    </v-row>

    <SectionHeader title="Balancete" class="pb-2" />
    <!-- Balancete -->
    <v-card rounded outlined>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        sort-by="date"
        group-by="date"
        no-data-text="Nenhuma movimentação encontrada"
        no-results-text="Nenhuma movimentação encontrada"
        outlined
        disable-sort
        :sort-desc="true"
        disable-pagination
        disable-filtering
        hide-default-footer
        show-group-by
      >
        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.date="{ item }">
          {{ item.date | dateBR }}
        </template>

        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.income="{ item }">
          <span v-if="item.multiplier == '1'" class="my-0 text-no-wrap caption">
            {{ item.description }}
          </span>
        </template>

        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.expense="{ item }">
          <span
            v-if="item.multiplier == '-1'"
            class="my-0 text-no-wrap caption"
          >
            {{ item.description }}
          </span>
        </template>

        <!-- eslint-disable vue/valid-v-slot  -->
        <template v-slot:item.payed_amount="{ item }">
          <span
            v-if="item.multiplier == '1'"
            class="my-0 text-no-wrap caption green--text"
          >
            {{ item.payed_amount | currency }}
          </span>
          <span
            v-if="item.multiplier == '-1'"
            class="my-0 text-no-wrap caption red--text"
          >
            {{ item.payed_amount | currency }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </KPage>
</template>

<script>
import moment from "moment";

import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import GraphicPie from "@/components/Graphic/Pie";

import FilterBar from "@/views/Finance/dashboard/FilterBar";

import ResumeCardList from "../_shared/ResumeCardList";

import { currencyBR } from "klawtil";

export default {
  components: {
    KPage,
    SectionHeader,
    GraphicPie,

    FilterBar,
    ResumeCardList,
  },

  data() {
    return {
      items: [],
      resume: {},
      headers: [
        { text: "Data", value: "data", width: 20 },
        {
          text: "Receita",
          value: "income",
          sortable: false,
          groupable: false,
          width: "45%",
        },
        {
          text: "Valor",
          value: "payed_amount",
          sortable: false,
          groupable: false,
          width: "10%",
        },
        {
          text: "Despesa",
          value: "expense",
          sortable: false,
          groupable: false,
          width: "45%",
        },
      ],
      filter: {},
    };
  },

  computed: {
    chartSettings() {
      return {
        names: "title",
        values: "value",
        title: "Receitas x Despesas",
        height: 400,
        _labelFormatter: "{a} <br/>{b} : {c} ({d}%)",
        labelFormatter: params => `
          ${params.seriesName} <br />
          ${params.data.name} : ${currencyBR(params.data.value)} ( ${
          params.percent
        }% )
        `,
        colors: ["#F44336", "#4CAF50"],

        series: [
          { title: "Despesas", value: this.resume.expenses },
          { title: "Receitas", value: this.resume.income },
        ],
      };
    },
  },

  methods: {
    /**
     *
     */
    async onFilter(payload) {
      try {
        this.$loading.show();

        await Promise.all([
          this.loadBalance(payload),
          this.loadResume(payload),
        ]);

        this.$loading.hide();
      } catch (error) {
        console.log(error);
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    async loadBalance(params) {
      try {
        const { data } = await this.$api.get(
          "/financials/reports/balance",
          params
        );
        this.items = data.data;
      } catch (error) {
        console.log(error);
        this.$message.serverError(error);
      }
    },

    async loadResume(params) {
      try {
        const { data } = await this.$api.get("/financials/resume", params);
        this.resume = data.data;
      } catch (error) {
        console.log(error);
        this.$message.serverError(error);
      }
    },
  },
};
</script>

<style>
</style>
